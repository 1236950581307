import React, { useState } from 'react';
import API from '../api/Instance';

export const Register = () => {
  const [registerForm, setRegisterForm] = useState({
    username: '',
    password: '',
    confirm_password: '',
    duplicateCheck: false,
  });

  /**
   * 회원가입 폼 입력
   */
  const handleInput = (e) => {
    setRegisterForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  /**
   * 회원가입 - 사용자아이디 입력
   * 사용자 아이디에 새로운 값이 입력되면 이전 중복체크 결과를 무효화 한다.
   */
  const handleUserNameInput = (e) => {
    setRegisterForm((prev) => ({
      ...prev,
      username: e.target.value,
      duplicateCheck: false,
    }));
  };

  const duplicateCheck = (e) => {
    e.preventDefault();
    console.log(registerForm.username);

    API.get('/member/duplicate', { params: { username: registerForm.username } }).then((res) => {
      if (res.status === 200) {
        console.log(res.data);
        setRegisterForm((prev) => ({
          ...prev,
          duplicateCheck: !res.data,
        }));
      }
    });
  };

  const clickRegisterMember = (e) => {
    e.preventDefault();

    // 중복체크 여부
    if (!registerForm.duplicateCheck) {
      return;
    }

    // 비밀번호 확인
    if (registerForm.password !== registerForm.confirm_password) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }

    API.post('/member/join', registerForm).then((res) => {
      if (res.status === 200) {
        alert('회원가입 성공');
        window.location.href = '/login';
      } else {
        alert('회원가입 실패');
      }
    });
  };

  return (
    <section class="h-screen flex bg-gray-100">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight">회원가입</h1>
            <form class="space-y-4 md:space-y-6" action="#">
              <div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="아이디"
                  class="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
                  required=""
                  onChange={handleUserNameInput}
                />
                {registerForm.duplicateCheck ? (
                  <span className="text-gray-600 tracking-tight">사용할 수 있는 아이디입니다.</span>
                ) : (
                  <button onClick={duplicateCheck}>중복 확인</button>
                )}
              </div>
              <div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="••••••••"
                  class="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
                  required=""
                  onChange={handleInput}
                />
              </div>
              <div>
                <input
                  type="password"
                  name="confirm_password"
                  id="confirm_password"
                  placeholder="••••••••"
                  class="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4"
                  required=""
                  onChange={handleInput}
                />
              </div>
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <input id="terms" aria-describedby="terms" type="checkbox" class="w-4 h-4 border" required="" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="terms" class="font-light">
                    I accept the{' '}
                    <a class="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">
                      Terms and Conditions
                    </a>
                  </label>
                </div>
              </div>
              <button
                type="submit"
                class="w-full focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={clickRegisterMember}>
                회원가입
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                이미 계정이 있으신가요?{' '}
                <a href="/login" class="font-medium text-primary-600 hover:underline dark:text-primary-500">
                  Login here
                </a>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
