import { AdvancedMarker, APIProvider, Map } from '@vis.gl/react-google-maps';

import jsonp from 'jsonp';
import React, { useEffect, useState } from 'react';

export const Maps = ({ address }) => {
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });

  const [shouldRender, setShouldRender] = useState(true);

  const containerStyle = {
    width: '500px',
    height: '500px',
  };

  useEffect(() => {
    // 위경도 변환 APi 호출
    if (!address) return;

    setShouldRender(true);

    const url =
      'https://api.vworld.kr/req/address?service=address&request=getcoord&version=2.0&crs=epsg:4326&address=' +
      address.address +
      '&refine=true&simple=false&format=json&type=road&key=' +
      process.env.REACT_APP_VWORLD_API_KEY;

    jsonp(url, null, (err, response) => {
      if (err) {
        console.error('호출 실패');
      } else {
        // 1. JSONP 응답에서 실제 JSON 데이터에 접근
        const { response: apiResponse } = response;

        // 2. 필요한 값 추출 (status, x, y 좌표 등)
        const status = apiResponse.status;
        const coordinates = apiResponse.result ? apiResponse.result.point : null;

        if (apiResponse.status === 'OK' && coordinates) {
          setLocation({
            lat: parseFloat(coordinates.y),
            lng: parseFloat(coordinates.x),
          });
        } else {
          console.error('좌표 변환 실패');
        }

        setShouldRender(false); // API 호출 완료 시 로딩 중단
      }
    });
  }, [address]);

  if (shouldRender) {
    return <p>Loading map...</p>; // 로딩 중일 때 표시
  }

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}>
      {location ? (
        <Map style={{ width: '500px', height: '500px' }} defaultCenter={location} gestureHandling={'greedy'} defaultZoom={15} />
      ) : (
        <p>Location not found.</p>
      )}
    </APIProvider>
  );
};
